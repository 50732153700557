@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400&family=DM+Sans:ital,opsz,wght@0,9..40,400;0,9..40,600;1,9..40,400&display=swap");

@font-face {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/Rajdhani-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: 400;
    src: url("./fonts/Rajdhani-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/Rajdhani-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Rajdhani";
    font-style: normal;
    font-weight: bolder;
    src: url("./fonts/Rajdhani-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Adidas";
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/ADIDAS.otf") format("opentype");
}

html,
body {
    min-height: 100vh;
    padding: 0;
    margin: 0;
    background: #0f1209;
    font-family: "Rajdhani", sans-serif;
}

a,
div,
p,
li,
span,
small,
big,
section,
nav,
header,
footer,
button,
input,
textarea,
select,
option,
label {
    font-family: "Rajdhani", sans-serif;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: "Rajdhani", sans-serif;
    font-weight: bold;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}

@keyframes slideRightToLeft {
    0% {
        left: 80%;
        opacity: 0%;
    }
    20% {
        opacity: 100%;
    }
    100% {
        left: -20%;
    }
}

@keyframes borderSpin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@layer utilities {
    .card-wrapper {
        @apply relative overflow-hidden;
    }
    .card-wrapper::before {
        background: conic-gradient(#37edc8 0deg, #37edc8 0deg, transparent 80deg);

        @apply absolute left-[-25%] top-[-25%] h-[150%] w-[150%] animate-border-spin content-[''];
    }
    .card-content {
        @apply absolute left-[1px] top-[1px] h-[calc(100%-2px)] w-[calc(100%-2px)] bg-[#111];
    }

    .sliderblock {
        background: radial-gradient(
            circle,
            rgba(11, 131, 107, 1) 30%,
            rgba(67, 255, 217, 1) 60%,
            rgba(11, 131, 107, 1) 80%
        );
    }

    .scrollbar {
        overflow: scroll;
        scrollbar-color: #37edc8;
        scrollbar-width: thin;
    }

    .mc-button {
        -webkit-clip-path: polygon(0 0, 84% 0, 100% 100%, 16% 100%);
        clip-path: polygon(0 0, 84% 0, 100% 100%, 16% 100%);
        background: #36edc8;
        border: 0;
        color: #000;
        font-weight: 700;
        letter-spacing: auto;
        line-height: 1.2em;
        box-shadow: 6px 0 #000;
        outline: transparent;
        text-transform: uppercase;
        overflow: none;
        padding: 3px 30px;
        transition: background 0.5s ease-in-out;
    }
    .mc-button:hover {
        background: #65ffe0;
    }
    .mc-button:disabled {
        background: #666;
        color: #ccc;
        box-shadow: 6px 0 #000;
    }
    .slanted-corner-no-bg {
        position: relative;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
    }
    .slanted-corner {
        position: relative;
        background: #111;
        border: 1px solid #239880;
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%);
    }
    .text-shadow {
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.75);
    }
    .absolute-center {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translate(-50%, 0%);
    }
    .scrollbar {
        scrollbar-width: 1px;
        scrollbar-color: #65ffe0;
    }
    .tr-corner {
        position: absolute;
        top: 0;
        right: 0;
    }
    .tr-corner::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 15px;
        height: 3px;
        background: #239880;
    }
    .tr-corner::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 3px;
        height: 15px;
        background: #239880;
    }
    .bl-corner {
        position: absolute;
        bottom: 0;
        left: 0;
    }
    .bl-corner::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 15px;
        height: 3px;
        background: #239880;
    }
    .bl-corner::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 3px;
        height: 15px;
        background: #239880;
    }
    .br-corner {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    .br-corner::before {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 15px;
        height: 3px;
        background: #239880;
        z-index: 4;
    }
    .br-corner::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 3px;
        height: 15px;
        background: #239880;
        z-index: 3;
    }
    .tl-corner {
        position: absolute;
        top: 0;
        left: 0;
    }
    .tl-corner::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 15px;
        height: 3px;
        background: #239880;
        z-index: 2;
    }
    .tl-corner::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 15px;
        background: #239880;
        z-index: 1;
    }
    .bl-corner,
    .br-corner,
    .tl-corner,
    .tr-corner {
        transition: all 0.1s ease-in-out;
    }
    .corner-container {
        border: 1px solid #239880;
        position: relative;
        transition: all 0.1s ease-in-out;
    }
    .corner-container:hover {
        border: 1px solid #35edc8;
    }
    .corner-container:hover .bl-corner {
        bottom: -10px;
        left: -10px;
    }
    .corner-container:hover .br-corner {
        bottom: -10px;
        right: -10px;
    }
    .corner-container:hover .tl-corner {
        top: -10px;
        left: -10px;
    }
    .corner-container:hover .tr-corner {
        top: -10px;
        right: -10px;
    }
    .corner-container:hover .bl-corner::before,
    .corner-container:hover .br-corner::before,
    .corner-container:hover .tl-corner::before,
    .corner-container:hover .tr-corner::before,
    .corner-container:hover .bl-corner::after,
    .corner-container:hover .br-corner::after,
    .corner-container:hover .tl-corner::after,
    .corner-container:hover .tr-corner::after {
        background: #35edc8;
    }
    .glow-yellow {
        box-shadow: 0px 0px 8px #ede636;
    }
    .highlight-leaderboard-cell {
        font-weight: bold;
        color: white;
        text-decoration: underline;
    }
    .leaderboard-row td:nth-child(1),
    .leaderboard-row td:nth-child(2) {
        max-width: 120px;
    }
    .text-shadow-md {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    }
    .text-shadow-white {
        text-shadow: 0px 0px 7px rgba(255, 255, 255, 0.35);
    }
    .missions-grid {
        display: grid;
        max-width: 600px;
        margin: 0 auto;
        border: 1px solid #333;
        position: relative;
        z-index: 10;
        grid-template-rows: 3;
        grid-template-columns: 1;
        position: relative;
        gap: 1rem;
    }
    .missions-grid > div:nth-child(1) {
        position: sticky;
        z-index: 10;
        top: 0;
    }
    .missions-grid > div:nth-child(3) {
        position: sticky;
        z-index: 10;
        bottom: 0;
    }
    @media screen and (min-width: 640px) {
        .missions-grid > div:nth-child(2) {
            height: 100%;
        }
    }
    .card-container {
        position: relative;
        height: 300px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .card {
        display: flex;
        height: 190px;
        width: 120px;
        padding: 10px;
        border-radius: 10px;
        box-shadow: 0.25rem 0 1.5rem #000;
        /*   margin-left: -50px; */
        transition: 0.4s ease-out;
        position: relative;
        left: 0px;
        justify-content: space-evenly;
    }

    .card:not(:first-child) {
        margin-left: -50px;
    }

    .card:hover {
        transform: translateY(-10px);
        transition: 0.4s ease-out;
    }

    .card:hover ~ .card {
        position: relative;
        left: 50px;
        transition: 0.4s ease-out;
    }
    .flex-divider > div {
        margin-right: 5px;
    }
    .flex-divider > div:after {
        content: "/";
        height: 100%;
        margin-left: 5px;
    }

    .flex-divider > div:nth-last-child(1) {
        margin-right: 0;
    }

    .flex-divider > div:nth-last-child(1):after {
        margin-right: 0;
        content: "";
    }

    @media screen and (max-width: 480px){
        .flex-divider > div:after {
            content: "";
        }
    }
}

/* Hide arrows in Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide arrows in Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}
